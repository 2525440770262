import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import parse from 'html-react-parser';

import AnimateIn from '../animate-in';

import './styles.scss';

const ContactPromo = ({ data, style, innerRef }) => {
  const pageData = get(data, 'contactList.contactPromoList[0]');

  const ComponentProps = {
    main: get(pageData, 'titleAndDescription'),
    callUsTitle: get(pageData, 'callUsTitle'),
    callUsNumber: get(pageData, 'contactUsNumber'),
    emailTitle: get(pageData, 'contactEmailField'),
    email: get(pageData, 'contactEmail'),
  };

  return (
    <section className="contact-promo">
      <div className="contact-content-navy without-button-container">
        <div className="wrapper">
          <div className="contact-flex-container" style={style} ref={innerRef}>
            <div className="contact-flexbox">
              <div className="contact-flex-content">
                {ComponentProps.main ? parse(ComponentProps.main) : null}
              </div>
            </div>
            <div className="contact-flexbox">
              <div className="contact-flex-content contact-us">
                <h4>{ComponentProps.callUsTitle}</h4>
                <h6 className="contact-details">
                  <a
                    href={`tel: ${ComponentProps.callUsNumber}`}
                    className="phone"
                  >
                    {ComponentProps.callUsNumber}
                  </a>
                </h6>
              </div>
            </div>
            <div className="contact-flexbox">
              <div className="contact-flex-content contact-us">
                <h4>{ComponentProps.emailTitle}</h4>
                <h6 className="contact-details">
                  <a href={`mailto: ${ComponentProps.email}`} className="email">
                    {ComponentProps.email}
                  </a>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ContactPromo.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(ContactPromo);
