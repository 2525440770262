import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import AnimateIn from '../animate-in';

import Clock from '../../assets/svgs/icon-clock.svg';
import Heart from '../../assets/svgs/icon-heart.svg';
import Cover from '../../assets/svgs/icon-cover.svg';

import './styles.scss';

const WhyBoltAccess = ({ style, innerRef }) => (
  <section className="why-bolt-access-content">
    <div className="wrapper">
      <div className="content-container" style={style} ref={innerRef}>
        <h4>Why bolt access</h4>
        <div className="list-content">
          <Clock />
          <h4>Top rated markets</h4>
          <p className="sub-heading">
            Get access to our top rated carriers for home, auto and small
            commercial.
          </p>
        </div>
        <div className="list-content">
          <Heart />
          <h4>Designed for You</h4>
          <p className="sub-heading">
            A real time comparative rater bundled into your agent portal where
            you can write, track and manage all of your new and existing
            business.
          </p>
        </div>
        <div className="list-content">
          <Cover />
          <h4>Agent benefits</h4>
          <p className="sub-heading">
            There are never any volume requirements and you own 100% of your
            book of business.
          </p>
        </div>
        <Link to="/about-us">
          <span>Find out more</span>
        </Link>
      </div>
    </div>
  </section>
);

WhyBoltAccess.propTypes = {
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(WhyBoltAccess);
